<script lang="ts">
	import { browser } from '$app/environment';
	let isVisible = false;

	function scrollUp() {
		if (browser) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
	if (browser) {
		window?.addEventListener('scroll', () => {
			isVisible = window.scrollY > 100;
		});
	}
</script>

{#if isVisible}
	<button class="scroll-up" type="button" on:click={scrollUp}> ↑ </button>
{/if}
