<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 44 44"
	enable-background="new 0 0 44 44"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<g>
		<path
			fill="currentColor"
			d="M16.756,19.026h11.146c0.553,0,1-0.448,1-1s-0.447-1-1-1H16.756c-0.552,0-1,0.448-1,1
		S16.204,19.026,16.756,19.026z"
		/>
		<path
			fill="currentColor"
			d="M15.756,22.994c0,0.553,0.448,1,1,1l16.146-0.001c0.553,0,1-0.447,1-1s-0.447-1-1-1l-16.146,0
		C16.204,21.994,15.756,22.442,15.756,22.994z"
		/>
		<path
			fill="currentColor"
			d="M43.07,8.694c0.07,0.177,0.134,0.357,0.216,0.528c-0.551-1.15-0.842-2.458-0.842-3.782
		c0-1.168,0.22-2.297,0.654-3.355c0.126-0.309,0.091-0.66-0.096-0.937c-0.186-0.277-0.497-0.443-0.83-0.443H6.447
		c-2.757,0-5,2.231-5,4.975c0,0.269,0.038,0.528,0.08,0.786c-0.032-0.198-0.054-0.4-0.062-0.605L1.447,5.859v30.066
		c0,3.597,2.942,6.523,6.56,6.523h35.44V8.719L43.07,8.694z M6.447,2.705h34.342c-0.229,0.885-0.345,1.8-0.345,2.734
		c0,1.096,0.164,2.186,0.48,3.214H6.447c-1.654,0-3-1.334-3-2.974S4.792,2.705,6.447,2.705z M3.447,35.925V9.633l0,0
		c0.838,0.631,1.87,1.02,3,1.02h1.314v29.77C5.363,40.294,3.447,38.336,3.447,35.925z M41.446,40.448H9.761V10.653h31.686V40.448z"
		/>
	</g></svg
>
