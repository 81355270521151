<script>
	import { fade } from 'svelte/transition';
	import { cartStorage } from '../../stores/preferences';
	import PriceSelector from '../../components/PriceSelector.svelte';
</script>

{#key $cartStorage}
	<span in:fade>
		<PriceSelector isHeader={true} article={$cartStorage?.total_price ?? 0} />
	</span>
{/key}
