<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 16 16"
	enable-background="new 0 0 16 16"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<path
		d="M8.055,1.56c-3.59,0-6.5,2.91-6.5,6.5s2.91,6.5,6.5,6.5s6.5-2.91,6.5-6.5S11.644,1.56,8.055,1.56z M6.555,11.139V4.981
	l4,3.079L6.555,11.139z"
		fill="currentColor"
	/>
</svg>
