<script lang="ts">
	import cn from 'classnames';
	import { preferencesStorage } from '../../stores/preferences';
	import { onMount } from 'svelte';
	export let isMobile: boolean;

	// attribute to get mobile selector (show currency menu)
	let isOpenedMobileSelector: boolean = false;

	// Function to set currency
	// @refactor: from switch, consts
	function changeCurrency() {
		let willChange = $preferencesStorage.currency === 'Kč' ? 'EUR' : 'Kč';
		preferencesStorage.set({ currency: willChange, isFirstVisit: false });
		//todo: find better solution - some hook of change currency
		location.reload();
	}
	
	onMount(async () => {
		try {
			let isFirstVisit = $preferencesStorage.isFirstVisit || false;
			if (!isFirstVisit) return;
			const response = await fetch('https://ipapi.co/json/');
			const data = await response.json();
			const country = data.country;
			console.log('Country:', country);
			if (country === 'SK') {
				let currency = $preferencesStorage.currency;
				if (currency === 'EUR') return;
				preferencesStorage.set({ currency: "EUR", isFirstVisit: false });
				location.reload();
			} else {
				let currency = $preferencesStorage.currency;
				if (currency === 'Kč') return;
				preferencesStorage.set({ currency: "Kč", isFirstVisit: false});
				location.reload();
			}
		} catch (error) {
			console.error('Error fetching geolocation data:', error);
		}
	});
</script>

{#if isMobile}
	<div class={cn($$restProps.class, 'ml-auto inline-flex lg:items-center')}>
		<span class="mr-2 font-normal text-white opacity-60">Měna:</span>
		<div class="relative">
			<button
				class="flex items-center gap-x-2"
				on:click={() => (isOpenedMobileSelector = !isOpenedMobileSelector)}
			>
				<span class="font-bold uppercase">
					{$preferencesStorage.currency !== 'Kč' ? 'EUR' : 'CZK'}
				</span>
				<svg
					class="h-4 w-4 text-[#94979a] lg:hidden"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="m19 9-7 7-7-7"
					/>
				</svg>
			</button>

			{#if isOpenedMobileSelector}
				<ul
					class="bg-gray-dark absolute left-0 top-full flex flex-col text-base uppercase text-white"
				>
					<button class="cursor-pointer" on:click={changeCurrency}>
						{$preferencesStorage.currency === 'Kč' ? 'EUR' : 'CZK'}
					</button>
				</ul>
			{/if}
		</div>
	</div>
{:else}
	<div
		class={cn($$restProps.class, 'container mx-auto flex justify-center gap-x-6 lg:items-center')}
	>
		<span class="font-normal text-white opacity-60" style="margin-bottom:25px;">Měna: </span>

		<ul class="flex flex-row text-lg font-bold uppercase text-white">
			<button
				on:click={changeCurrency}
				class:lg:hover:bg-gray-900={$preferencesStorage.currency !== 'Kč'}
				class:lg:bg-gray-700={$preferencesStorage.currency === 'Kč'}
				class="cursor-pointer lg:px-6 lg:py-3 "
			>
				czk
			</button>
			<button
				on:click={changeCurrency}
				class:lg:hover:bg-gray-900={$preferencesStorage.currency !== 'EUR'}
				class:lg:bg-gray-700={$preferencesStorage.currency === 'EUR'}
				class="cursor-pointer lg:px-6 lg:py-3 transition-all"
			>
				eur
			</button>
		</ul>
	</div>
{/if}
