<svg
	class={$$restProps.class || ''}
	xmlns="http://www.w3.org/2000/svg"
	x="0px"
	y="0px"
	viewBox="0 0 16 16"
	xml:space="preserve"
>
	<rect fill="currentColor" y="13" width="5" height="3" />
	<rect fill="currentColor" x="11" y="13" width="5" height="3" />
	<rect fill="currentColor" y="7" width="16" height="3" />
	<rect fill="currentColor" x="8" y="1" width="5" height="3" />
</svg>
