<svg
	viewBox="0 0 16 16"
	x="0px"
	y="0px"
	xmlns="http://www.w3.org/2000/svg"
	xml:space="preserve"
	class={$$props.class}
>
	<path
		d="M8,0C3.581,0,0,3.581,0,8s3.581,8,8,8s8-3.581,8-8S12.418,0,8,0z M10.105,5.032H9.342 c-0.599,0-0.714,0.312-0.714,0.77V6.81h1.427L9.869,8.391H8.628v4.055h-1.49V8.391H5.895V6.81h1.245V5.645 c0-1.352,0.754-2.088,1.853-2.088c0.527,0,0.98,0.043,1.112,0.062V5.032z"
		fill="currentColor"
	/>
</svg>
