<svg
	xmlns="http://www.w3.org/2000/svg"
	x="0px"
	y="0px"
	viewBox="0 0 16 16"
	xml:space="preserve"
	class={$$restProps.class || ''}
	><path
		d="M14.222,1H1.778H0.889H0v11.143V14h1.778h12.445H16v-1.857V2.857V1H14.222z M14,3v1.239L8,7.518L2,4.239V3H14z M2,11.999 V6.192l6,3.279l6-3.278V12H2V11.999z"
		fill="currentColor"
	/>
</svg>
