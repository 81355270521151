<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 44 44"
	enable-background="new 0 0 44 44"
	xml:space="preserve"
	class={$$props.class || ''}
>
	<path
		fill="currentColor"
		d="M42.721,17.299l-5.886-1.682l2.973-5.352c0.217-0.391,0.148-0.877-0.167-1.193l-4.689-4.69
		c-0.315-0.316-0.803-0.385-1.192-0.167l-5.353,2.973l-1.682-5.887c-0.123-0.429-0.516-0.725-0.962-0.725H19.13
		c-0.446,0-0.839,0.296-0.961,0.726l-1.682,5.886l-5.352-2.973c-0.392-0.217-0.878-0.148-1.193,0.167l-4.69,4.69
		c-0.316,0.315-0.384,0.802-0.167,1.193l2.973,5.352l-5.887,1.682c-0.429,0.123-0.725,0.515-0.725,0.961v6.633
		c0,0.446,0.296,0.839,0.726,0.962l5.886,1.681l-2.973,5.353c-0.217,0.39-0.149,0.877,0.167,1.192l4.69,4.689
		c0.315,0.315,0.801,0.385,1.192,0.167l5.352-2.973l1.682,5.886c0.123,0.43,0.515,0.726,0.961,0.726h6.633
		c0.446,0,0.839-0.296,0.962-0.726l1.682-5.886l5.353,2.973c0.39,0.218,0.877,0.149,1.192-0.167l4.689-4.689
		c0.315-0.315,0.384-0.803,0.167-1.192l-2.973-5.353l5.886-1.681c0.43-0.123,0.726-0.516,0.726-0.962V18.26
		C43.446,17.814,43.15,17.421,42.721,17.299z M41.446,24.14l-6.372,1.819c-0.294,0.084-0.532,0.297-0.649,0.579
		s-0.099,0.602,0.05,0.868l3.219,5.794l-3.623,3.623l-5.794-3.218c-0.267-0.149-0.586-0.167-0.868-0.05
		c-0.281,0.116-0.495,0.355-0.579,0.649l-1.82,6.371h-5.125l-1.82-6.371c-0.083-0.294-0.297-0.532-0.579-0.649
		c-0.282-0.116-0.602-0.1-0.868,0.05l-5.794,3.218L7.2,33.2l3.218-5.794c0.148-0.267,0.167-0.586,0.05-0.868
		s-0.356-0.495-0.649-0.579L3.447,24.14v-5.125l6.373-1.821c0.293-0.083,0.532-0.297,0.649-0.579
		c0.117-0.282,0.099-0.602-0.05-0.869L7.2,9.953l3.624-3.624l5.793,3.219c0.267,0.147,0.586,0.166,0.869,0.05
		c0.282-0.117,0.495-0.356,0.579-0.649l1.82-6.372h5.125l1.82,6.373c0.084,0.293,0.298,0.532,0.579,0.649
		c0.282,0.116,0.602,0.098,0.868-0.05L34.07,6.33l3.623,3.623l-3.219,5.793c-0.148,0.267-0.167,0.586-0.05,0.869
		c0.117,0.282,0.355,0.495,0.649,0.579l6.372,1.821V24.14z"
	/>
	<path
		fill="currentColor"
		d="M22.447,15.27c-3.478,0-6.307,2.829-6.307,6.307c0,3.477,2.829,6.306,6.307,6.306
		c3.477,0,6.306-2.829,6.306-6.306C28.753,18.099,25.924,15.27,22.447,15.27z M22.447,25.883c-2.375,0-4.307-1.932-4.307-4.306
		s1.932-4.307,4.307-4.307s4.306,1.932,4.306,4.307S24.821,25.883,22.447,25.883z"
	/>
</svg>
