<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 44 44"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<path
		fill="currentColor"
		d="M41.155,4.842l-1.975-1.974c-0.832-0.833-1.939-1.292-3.117-1.292s-2.285,0.458-3.117,1.292L5.458,30.356
	c-0.12,0.12-0.208,0.269-0.254,0.433l-2.718,9.513c-0.1,0.349-0.002,0.725,0.254,0.981c0.19,0.19,0.445,0.293,0.707,0.293
	c0.092,0,0.184-0.013,0.275-0.038l9.513-2.718c0.163-0.047,0.312-0.135,0.432-0.255l27.488-27.488
	C42.874,9.358,42.874,6.561,41.155,4.842z M11.973,32.228c-0.996-0.996-2.185-1.724-3.425-2.134l21.4-21.4l5.381,5.381L13.984,35.42
	C13.611,34.284,12.925,33.18,11.973,32.228z M7.003,31.767c1.257,0.182,2.527,0.847,3.555,1.875
	c1.015,1.015,1.628,2.219,1.731,3.368l-7.387,2.11L7.003,31.767z M39.741,9.663l-2.998,2.998L31.362,7.28l2.998-2.998
	c0.91-0.91,2.496-0.91,3.406,0l1.975,1.974C40.681,7.196,40.681,8.724,39.741,9.663z"
	/>
</svg>
