<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 16 16"
	enable-background="new 0 0 16 16"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<path
		d="M14.555,6.982l-2.307-0.659l1.165-2.097l-1.524-1.524L9.792,3.867L9.132,1.56H6.977L6.318,3.867L4.221,2.702L2.696,4.226
	l1.165,2.097L1.555,6.982v2.156l2.307,0.659l-1.165,2.097l1.524,1.524l2.097-1.165l0.659,2.307h2.156l0.659-2.307l2.097,1.165
	l1.524-1.524l-1.165-2.097l2.307-0.659V6.982z M8.055,9.785c-0.953,0-1.725-0.772-1.725-1.725s0.772-1.725,1.725-1.725
	c0.952,0,1.725,0.772,1.725,1.725S9.007,9.785,8.055,9.785z"
		fill="currentColor"
	/>
</svg>
