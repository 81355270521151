<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 22 19"
	enable-background="new 0 0 22 19"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<path
		fill="currentColor"
		d="M17.634,6.95l-1.933-5.9H6.298l-1.933,5.9H1l3,11h14l3-11H17.634z M7.603,2.85h6.793l1.343,4.1H6.26
	L7.603,2.85z M8.9,14.95H7.099v-5H8.9V14.95z M11.9,14.95h-1.801v-5H11.9V14.95z M14.9,14.95h-1.801v-5H14.9V14.95z"
	/>
</svg>
