<script lang="ts">
	import { scooterStorage } from '../../stores/preferences';
	import HPBrandSearch from '../HPBrandSearch.svelte';
	import Heading from './Heading.svelte';

	import Pencil from '$lib/images/svg/pencil.svelte';
	import Parameters from '$lib/images/svg/parameters.svelte';
	import GearOutline from '$lib/images/svg/gear-outline.svelte';
	import Scooter from '$lib/images/svg/scooter.svelte';
	import classNames from 'classnames';
	export let brands_search: any[] = [];
	let mobileMenuItemsClass =
		'flex flex-row cursor-pointer py-4 bg-white text-black justify-start align-middle items-center ' +
			'w-full px-5 gap-x-4';

	// @TODO: create object to fill menus? would be nice
</script>

{#if $scooterStorage?.name?.length > 0}
	<!-- Homepage scooter selector/desktop -->
	<div
		class="hidden text-center lg:grid lg:h-[23.125rem] lg:w-full lg:max-w-2xl lg:grid-cols-5 lg:gap-x-7"
	>
		<a
			href="/skutr-{$scooterStorage?.url}/"
			title="detail skutru"
			class="glow col-span-2 flex w-full grow flex-col bg-white p-9 text-black"
		>
			<div class="w-full items-center text-center align-middle">
				<Heading tag="strong" size="sm" class="mb-5">Váš skútr</Heading>
				<img
					src={$scooterStorage?.image}
					alt={$scooterStorage.name}
					class="mx-auto w-full max-w-48"
				/>
				<Heading tag="strong" class="mb-5 text-lg">
					{$scooterStorage?.producer}
					{$scooterStorage?.name}, {$scooterStorage?.ccm} ccm
				</Heading>
			</div>
		</a>

		<div class="col-span-3 hidden w-full font-bold text-black lg:grid lg:grid-cols-2 lg:gap-7">
			<a
				id="select"
				href="/skutr-{$scooterStorage?.url}/"
				title={$scooterStorage.name}
				class="glow flex h-44 w-full flex-col items-center justify-center bg-yellow align-middle"
			>
				<GearOutline class="mb-5 w-11" />
				<span class="inline-block">Náhradní díly</span>
			</a>
			<a
				id="select"
				href="/skutr-{$scooterStorage?.url}/technicke-udaje/"
				title="Technické údaje"
				class="glow flex h-44 w-full flex-col items-center justify-center bg-white align-middle"
			>
				<Parameters class="mb-5 w-11" />
				<span class="inline-block">Technické parametry</span>
			</a>
			<a
				id="select"
				href="/skutr-{$scooterStorage?.url}/blog/"
				title="návody a tipy"
				class="glow flex h-44 w-full flex-col items-center justify-center bg-white align-middle"
			>
				<Pencil class="mb-5 w-11" />
				<span class="inline-block">Články a tipy</span>
			</a>
			<a
				id="select"
				href="/skutry/"
				title="najít jiný skútr"
				class="glow flex h-44 w-full flex-col items-center justify-center bg-white align-middle"
			>
				<Scooter class="mb-5 w-11" />
				<span class="inline-block">Najít jiný skútr</span>
			</a>
		</div>
	</div>

	<!-- Homepage scooter selector/mobile -->
	<div
		class="mx-auto mt-10 flex w-full max-w-80 flex-col flex-wrap items-center gap-y-2.5 text-lg font-bold lg:hidden"
	>
		<a
			href="/skutr-{$scooterStorage?.url}/"
			title="detail skutru"
			class="glow flex w-full max-w-80 flex-col items-center bg-white p-9 text-center align-middle text-black"
		>
			<Heading size="sm" tag="strong" class="mb-5">Váš skútr</Heading>
			<img
				src={$scooterStorage?.image}
				alt={$scooterStorage.name}
				class="m-auto h-auto max-w-36"
			/>
			<Heading tag="h2" size="lg" class="mt-5">
				{$scooterStorage?.producer}
				{$scooterStorage?.name}, {$scooterStorage?.ccm} ccm</Heading
			>
		</a>

		<a
			href="/skutr-{$scooterStorage?.url}/"
			title={$scooterStorage.name}
			class={classNames(mobileMenuItemsClass, 'bg-yellow')}
		>
			<GearOutline class="w-6" />
			<span class="inline-block">Náhradní díly</span>
		</a>
		<a
			href="/skutr-{$scooterStorage?.url}/technicke-udaje/"
			title="technicke"
			class={mobileMenuItemsClass}
		>
			<Parameters class="w-6" />
			<span class="inline-block">Technické parametry</span>
		</a>
		<a href="/skutr-{$scooterStorage?.url}/blog/" title="návody a tipy" class={mobileMenuItemsClass}>
			<Pencil class="w-6" />
			<span class="inline-block">Články a tipy</span>
		</a>
		<a href="/skutry/" title="najít jiný skútr" class={mobileMenuItemsClass}>
			<Scooter class="w-8" />
			<span class="inline-block">Najít jiný skútr</span>
		</a>
	</div>
{:else}
	<Heading tag="h1" class="text-3xl leading-snug drop-shadow-lg lg:text-6xl">
		<span class="lg:block lg:w-full">Náhradní díly, technické údaje,</span>
		<span class="mt-2 lg:block lg:w-full"
			>návody a videa
			<span class="text-primary">pro každý skútr!</span>
		</span>
	</Heading>
	<HPBrandSearch brands_search={brands_search} class="mt-8"/>
{/if}
