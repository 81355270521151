<script lang="ts">
    import { page } from '$app/stores'

    $: {
        if (typeof gtag !== 'undefined') {
            gtag('config', 'G-KJVEL6VNSB', {
                page_title: document.title,
                page_path: $page.url.pathname,
            })
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });
            gtag('consent', 'update', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
        }
    }
</script>

<svelte:head>
    <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-KJVEL6VNSB">
    </script>
    <script>
        window.dataLayer = window.dataLayer || []

        function gtag() {
            dataLayer.push(arguments)
        }

        gtag('js', new Date())
        gtag('config', 'G-KJVEL6VNSB')
    </script>
</svelte:head>
