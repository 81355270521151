<svg
	viewBox="0 0 16 16"
	xmlns="http://www.w3.org/2000/svg"
	xml:space="preserve"
	x="0px"
	y="0px"
	fill="none"
	class={$$props.class}
>
	<path
		fill="currentColor"
		d="M3.98,2.131c0,0,0.391,0.001,0.42,0.002C4.603,2.141,5.017,2.18,5.199,2.269 c0.912,0.445,1.46,3.116,0.898,3.961C5.899,6.528,4.912,6.438,4.768,6.766c-0.038,0.089,0.044,0.25,0.044,0.25 c0.721,2.072,1.473,3.344,3.546,4.064c0,0,0.571,0.241,0.865,0.241c0.038,0,0.071-0.004,0.098-0.013 c0.332-0.111,0.267-1.12,0.582-1.272c0.199-0.096,0.533-0.142,0.925-0.142c1.111,0,2.696,0.371,3.035,1.04 c0.092,0.181,0.129,0.596,0.136,0.799c0.048,1.324-1.073,2.4-2.397,2.4c-0.001,0-0.002,0-0.003,0 C5.1,14.124,1.99,10.374,2.002,4.533C2.003,3.236,3.03,2.131,4.314,2.131 M4.177,0C1.879,0,0.005,1.913,0,4.264 c-0.007,3.571,0.981,6.423,2.936,8.475C4.991,14.899,7.95,15.995,11.73,16c1.167,0,2.296-0.48,3.103-1.316 c0.792-0.821,1.205-1.891,1.164-3.015c-0.016-0.448-0.087-1.084-0.332-1.568c-1.011-1.995-4.355-2.053-4.733-2.053 c-0.715,0-1.283,0.106-1.735,0.326C8.804,8.566,8.539,8.84,8.357,9.13C7.723,8.812,7.304,8.376,6.916,7.598 c0.216-0.141,0.42-0.329,0.59-0.585C8.393,5.681,7.941,3.658,7.781,3.07C7.521,2.115,6.98,0.862,5.874,0.323 c-0.48-0.233-1.101-0.303-1.539-0.32C4.282,0.001,4.23,0,4.177,0L4.177,0z"
	/>
</svg>
