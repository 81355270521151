<script lang="ts">
	import './styles.css';
	import Header from './Header.svelte';
	import Footer from './Footer.svelte';
	import CartAddPopup from './(popups)/_CartAddPopup.svelte';
	import OrderStornoPopup from "./(popups)/OrderStornoPopup.svelte";
	import ScrollUp from '../components/ScrollUp.svelte';
	import { browser } from '$app/environment';
	import { SvelteToast } from '@zerodevx/svelte-toast';
	import OrderEditPopup from './(popups)/OrderEditPopup.svelte';
	import { updated } from '$app/stores';
	import Analytics from "$lib/Analytics.svelte";
	import {PUBLIC_GENERAL_SERVICE_URL} from "$env/static/public";
	import {generateSignature} from "$lib/utils/Signature";
	import GraphDetailPopup from "./(popups)/GraphDetailPopup.svelte";
	export let disabledScroll = false;
	export let data;
	
	let options = {};
	let brands_search: any;
	
	if (browser) {
		window.addEventListener('keydown', (e) => {
			if ((e.key === 'k' && e.metaKey) || (e.key === 'k' && e.ctrlKey)) {
				window.location.href = '/vyhledavani/';
			}
		});
	}
	
</script>

<SvelteToast {options} />

<div class="app" class:scroll-lock={disabledScroll}>
	<Header bind:disabledScroll brands_search={data.brands_search}/>
	<!--	<AdminPanel/> -->

	<main data-sveltekit-reload={$updated ? '' : 'off'}>
		<slot />
	</main>

	<CartAddPopup />
	<OrderStornoPopup />
	<GraphDetailPopup />
	<OrderEditPopup />
	<Footer />
	<ScrollUp />
	<Analytics />
</div>
