<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 16 16"
	enable-background="new 0 0 16 16"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<rect x="11.055" y="1.56" width="4" height="13" fill="currentColor" />
	<rect x="6.055" y="5.56" width="4" height="9" fill="currentColor" />
	<rect x="1.055" y="9.56" width="4" height="5" fill="currentColor" />
</svg>
